import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FormHelperText } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REQUEST_STATUS } from "../../shared/types/request.type";
import authActions from "../../store/Auth/auth.action";
import styles from "./SignIn.module.scss";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const theme = createTheme();

const SignIn = () => {
  const [helperText, setHelperText] = useState<any>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginInfo = {
      email: data.get("email"),
      password: data.get("password"),
    };
    const result: any = await dispatch(authActions.login(loginInfo) as any);
    const {
      meta: { requestStatus },
      // payload,
    } = result || {};
    if (requestStatus === REQUEST_STATUS.FULFILLED) {
      navigate("/");
    } else if (requestStatus === REQUEST_STATUS.REJECTED) {
      setHelperText(t("LOGIN_FORM.passwordIncorrect"));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon color="primary" />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("LOGIN_FORM.login")}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("LOGIN_FORM.email")}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("LOGIN_FORM.password")}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={`${styles["btn-sign-in"]}`}
              sx={{ mt: 3, mb: 2 }}
            >
              {t("LOGIN_FORM.login")}
            </Button>
            <FormHelperText
              style={{
                color: red.A400,
              }}
            >
              {helperText}
            </FormHelperText>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Button, TableHead, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getMedia, separateNumber } from 'src/shared/utils/common.util';
import MuiPagination from 'src/components/MuiPagination';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { requestSelector } from 'src/store/request/request.slice';
import { truncateText } from 'src/shared/utils/helpers';
import MuiModal from 'src/components/MuiModal';
import requestAction from 'src/store/request/request.action';
import MuiTextArea from 'src/components/MuiTextArea';
import { useForm } from 'react-hook-form';
import { KOL_REQUEST_STATUS } from 'src/store/request/request.type';
import { useTranslation } from 'react-i18next';
import ImageViewer from 'src/components/ImageViewer';
import './index.scss';
import ReadMoreText from 'src/components/ReadMoreText';
import LoadingSkeleton from 'src/components/LoadingSkeleton';

const TableComponent = ({
  rows,
  page,
  handleChangePage,
  setOffset,
  tabName,
  disableDetail = false,
  buttonDisabled = false,
}: {
  rows: Record<string, any>[];
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  setOffset: (newOffset: number) => void;
  tabName: string;
  disableDetail?: boolean;
  buttonDisabled?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { paginationMeta, isLoading } = useAppSelector(requestSelector);
  const [totalPage, setTotalPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [visible, setVisible] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalReject, setShowModalReject] = React.useState(false);
  const [userSelected, setUserSelected] = React.useState('');
  const [currentUser, setCurrentUser] = React.useState<any>({});
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      .trim()
      .required(t('PROFILE_PAGE.rejectReasonRequired')!),
  });
  const { control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const TABLE_HEAD = [
    'nickName',
    'nameAndEmail',
    'phoneNumber',
    'videoPrice',
    'socialNetwork',
    'socialLink',
    'follower',
    'brief',
  ];

  const rejectedReasonField: any =
    tabName === KOL_REQUEST_STATUS.REJECTED
      ? {
          label: 'rejectReason',
          value: 'rejectedReason',
        }
      : {};

  const MODAL_DETAIL = [
    { label: 'nickName', value: 'nickName' },
    { label: 'fullName', value: 'idolName' },
    { label: 'email', value: 'idolEmail' },
    { label: 'phoneNumber', value: 'idolPhoneNumber' },
    { label: 'videoPrice', value: 'price' },
    {
      label: 'socialNetwork',
      value: 'social',
    },
    {
      label: 'socialLink',
      value: 'linkSocial',
    },
    {
      label: 'follower',
      value: 'follower',
    },
    {
      label: 'brief',
      value: 'description',
    },
    rejectedReasonField,
    {
      label: 'idCardFront',
      value: 'idCardFront',
    },
    {
      label: 'idCardBack',
      value: 'idCardBack',
    },
  ] as any;

  const listTableHead: string[] = disableDetail
    ? TABLE_HEAD
    : [...TABLE_HEAD, ''];

  const openURL = (event: any, url: string) => {
    event.stopPropagation();
    window.open(url);
  };
  const toggleVisible = () => setVisible(!visible);
  const toggleModalStatus = () => setShowModal(!showModal);
  const toggleModalReject = () => setShowModalReject(!showModalReject);
  const onShowTooltip = React.useCallback((userId: string) => {
    setUserSelected(userId);
    toggleVisible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleShowDetail = (_id: string) => {
    if (!disableDetail) {
      setShowModal(true);
      const result: any = rows.find((item) => item._id === _id);
      setCurrentUser({
        ...result,
        social: result.idolInformation.social,
        linkSocial: result.idolInformation.linkSocial,
        follower: result.idolInformation.follower,
        description: result.idolInformation.description,
      });
    }
  };
  const onShowModalReject = () => {
    toggleModalStatus();
    toggleModalReject();
  };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const open = Boolean(anchorEl);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(parseInt(event.target.value, 10));
  };

  const handleApprove = () => {
    dispatch(
      requestAction.approveStatusRequest({
        kolId: currentUser._id,
        approvedStatus: true,
      })
    );
    toggleModalStatus();
  };

  const handleReject = (data: any) => {
    dispatch(
      requestAction.approveStatusRequest({
        kolId: currentUser._id,
        approvedStatus: false,
        reason: data.reason,
      })
    );
    toggleModalReject();
  };

  React.useEffect(() => {
    if (Object.keys(paginationMeta).length) {
      const { perPage, totalPages } = paginationMeta;
      setRowsPerPage(perPage);
      setTotalPage(totalPages);
    }
  }, [paginationMeta]);
  React.useEffect(() => {
    if (showModalReject) setValue('reason', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalReject]);

  return (
    <div className="table-wrap">
      <TableContainer className="table-container">
        <Table
          sx={{ minWidth: 650, maxHeight: '460px' }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow className="profile-table-head">
              {listTableHead.map((item: string, index: number) => (
                <TableCell key={`${item}-${index}`} className="profile-cell">
                  {item ? t(`PROFILE_TABLE_HEADER_COLUMNS.${item}`) : item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {
            isLoading ? (
              <LoadingSkeleton itemColumn={TABLE_HEAD.length} itemRow={4} />
            ) : (
              <TableBody className="profile-table-body">
                {rows.map((row: Record<string, any>, index: number) => {
                  const {
                    _id,
                    idolPhoneNumber,
                    price,
                    nickName,
                    idolName,
                    idolEmail,
                    idolInformation,
                  } = row || {};
                  return (
                    <TableRow
                      key={_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" scope="row">
                        <div className="user-info">
                          <div className="user-name">{nickName}</div>
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div className="user-info">
                          <div>
                            <div className="user-name">{idolName}</div>
                            <div className="user-email">{idolEmail}</div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div className="user-type" style={{ color: '#000' }}>
                          {idolPhoneNumber}
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div className="user-type" style={{ color: '#000' }}>
                          {`${separateNumber(price as number)} đ`}
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div>{idolInformation?.social}</div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <Tooltip
                          title={idolInformation?.linkSocial || ''}
                          open={
                            visible &&
                            userSelected ===
                              `${_id} ${idolInformation?.linkSocial}` &&
                            idolInformation?.linkSocial.length > 30
                          }
                          onClose={toggleVisible}
                          onOpen={() =>
                            onShowTooltip(`${_id} ${idolInformation?.linkSocial}`)
                          }
                        >
                          <div
                            className="user-id"
                            onClick={(event) =>
                              openURL(event, idolInformation?.linkSocial)
                            }
                          >
                            {truncateText(idolInformation?.linkSocial, 30)}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div>
                          {separateNumber(idolInformation?.follower, '.')}
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <Tooltip
                          title={idolInformation?.description || ''}
                          open={
                            visible &&
                            userSelected === _id &&
                            idolInformation?.description.length > 30
                          }
                          onClose={toggleVisible}
                          onOpen={() => onShowTooltip(_id)}
                        >
                          <div>
                            {truncateText(idolInformation?.description, 30)}
                          </div>
                        </Tooltip>
                      </TableCell>
                      {!disableDetail ? (
                        <TableCell align="left">
                          <div className="user-action" style={{ color: '#000' }}>
                            <Button
                              variant="text"
                              onClick={() => handleShowDetail(_id)}
                            >
                              <VisibilityIcon style={{ color: 'black' }} />
                            </Button>
                          </div>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
                {
                  !rows.length && (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className="user-row"
                    >
                      <TableCell colSpan={TABLE_HEAD.length} align='center'>{t("noData")}</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            )
          }
        </Table>
      </TableContainer>

      {!isLoading && rows?.length && paginationMeta ? (
        <MuiPagination
          count={totalPage}
          page={page}
          handleChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}

      {showModal && (
        <MuiModal
          title={t('PROFILE_MODAL.profile')}
          titleSubmit={buttonDisabled ? null : t('PROFILE_MODAL.approve')}
          titleCancel={buttonDisabled ? null : t('PROFILE_MODAL.reject')}
          open={showModal}
          onClose={() => setShowModal(false)}
          onCancel={onShowModalReject}
          onSubmit={handleApprove}
          disabled={buttonDisabled}
          maxHeight={'98vh'}
        >
          <div className="modal-list-container">
            {MODAL_DETAIL.map((item: any) => {
              const { label, value } = item || {};
              return (
                <div className="modal-row" key={label}>
                  {currentUser[value] && (
                    <div className="modal-title">
                      {label ? t(`PROFILE_MODAL.${label}`) : label}
                    </div>
                  )}
                  {value === 'description' && (
                    <div className="modal-sub-title">
                      <ReadMoreText textSize={50}>
                        {currentUser[`${value}`]}
                      </ReadMoreText>
                    </div>
                  )}

                  {value === 'linkSocial' && (
                    <div
                      className="user-id"
                      onClick={(event) =>
                        openURL(event, currentUser[`${value}`])
                      }
                    >
                      {currentUser[`${value}`]}
                    </div>
                  )}
                  {['idCardFront', 'idCardBack'].includes(value) &&
                    currentUser[value]?.path && (
                      <ImageViewer
                        src={getMedia(currentUser[value]?.path)}
                        alt={'id card'}
                      />
                    )}
                  {![
                    'linkSocial',
                    'idCardFront',
                    'idCardBack',
                    'description',
                  ].includes(value) && (
                    <div className="modal-sub-title">
                      {currentUser[`${value}`]}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </MuiModal>
      )}

      <MuiModal
        title={t('PROFILE_PAGE.rejectReason')}
        titleSubmit={t('PROFILE_PAGE.reply')}
        open={showModalReject}
        onClose={() => setShowModalReject(false)}
        onSubmit={handleSubmit(handleReject)}
        maxHeight="400px"
      >
        <MuiTextArea
          name="reason"
          control={control}
          placeholder={t('PROFILE_PAGE.rejectReason')!}
          maxLength={500}
          rows={6}
        />
      </MuiModal>
    </div>
  );
};

export default React.memo(TableComponent);

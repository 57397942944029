import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface globalState {
  pageTitle: string;
}

const initialState: globalState = {
  pageTitle: 'overview',
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changePageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
  },
});

export const globalSliceActions = globalSlice.actions;
export const globalSelector = (state: RootState) => state.globalReducer;
export default globalSlice.reducer;

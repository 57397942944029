export const truncateText = (
  text: string | undefined | null,
  maxLength?: number
) => {
  if (!maxLength) maxLength = 15;
  if (text === undefined || text === null) return null;
  if (text.length > maxLength) {
    text = text.substr(0, maxLength) + "...";
  }
  return text;
};

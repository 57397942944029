import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableComponent from '../Table';
import toastify from 'src/components/Toastify';
import { InputAdornment, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import useDebounce from 'src/hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import requestAction from 'src/store/request/request.action';
import { requestSelector } from 'src/store/request/request.slice';
import { KOL_REQUEST_STATUS } from 'src/store/request/request.type';
import './index.scss';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="user-tab-panel">{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsComponent() {
  const [value, setValue] = React.useState(0);
  const [inputSearch, setInputSearch] = React.useState('');
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(20);
  const { t } = useTranslation();

  const { requestsProcessing, requestsFailed, requestsApproved, isChangedStatus } =
    useAppSelector(requestSelector);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputSearch(value);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const debouncedTextSearch = useDebounce(inputSearch, 500);

  const getTabStatus = (status: number) => {
    switch(status) {
      case 0: {
        return KOL_REQUEST_STATUS.INACTIVE
      }
      case 1: {
        return KOL_REQUEST_STATUS.REJECTED
      }
      default:
        return KOL_REQUEST_STATUS.ACTIVE
    }
  }
  
  React.useEffect(() => {
    const status = getTabStatus(value);

    if (isChangedStatus) {
      toastify({
        type: 'success',
        msg: t('updateStatusSuccess'),
      });
    }

    dispatch(
      requestAction.getRequestList({
        search: debouncedTextSearch,
        status,
        page,
        offset,
      })
    );
    // eslint-disable-next-line
  }, [value, page, offset, debouncedTextSearch, isChangedStatus]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          padding: '0 24px',
          position: 'relative',
        }}
        className="user-tab-container"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="users-tab"
        >
          <Tab label={t('PROFILE_PAGE.pendingProfile')} {...a11yProps(0)} />
          <Tab label={t('PROFILE_PAGE.rejectedProfile')} {...a11yProps(1)} />
          <Tab label={t('PROFILE_PAGE.approvedProfile')} {...a11yProps(2)} />
        </Tabs>

        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          onChange={handleInputChange}
          placeholder={t('searchPlaceholder')!}
          className="user-search"
          variant="standard"
          value={inputSearch}
        />
      </Box>

      <Box>
        <TabPanel value={value} index={0}>
          <TableComponent
            rows={requestsProcessing}
            page={page - 1}
            handleChangePage={handleChangePage}
            setOffset={setOffset}
            tabName={KOL_REQUEST_STATUS.INACTIVE}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <TableComponent
            rows={requestsFailed}
            page={page - 1}
            handleChangePage={handleChangePage}
            setOffset={setOffset}
            tabName={KOL_REQUEST_STATUS.REJECTED}
            buttonDisabled={true}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <TableComponent
            rows={requestsApproved}
            page={page - 1}
            handleChangePage={handleChangePage}
            setOffset={setOffset}
            tabName={KOL_REQUEST_STATUS.APPROVED}
            buttonDisabled={true}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}

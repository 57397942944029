import apiService from "../../shared/services/api.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MIDOL_ENDPOINT } from "../../shared/constants/endpoints.constant";

class RequestActions {
  getRequestList = createAsyncThunk(
    "bookings/getRequestList",
    async (
      requestBody: {
        search: string;
        status: string;
        page: number;
        offset: number;
      },
      { rejectWithValue }
    ) => {
      try {
        const { page, offset, status, search } = requestBody;
        const res: any = await apiService.get(
          `${MIDOL_ENDPOINT.BOOKING_REQUEST.GET_REQUEST}?search=${encodeURIComponent(
            search
          )}&status=${status}&page=${page}&offset=${offset}`,
          {}
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default new RequestActions();

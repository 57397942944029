export enum TRANSACTION_STATUS {
  PROCESSING = "PROCESSING",
  SUCCEEDED = "SUCCEEDED",
}

export const TRANSACTION_TYPE = {
  TRANSACTION_EVIDENCE: "TRANSACTION_EVIDENCE",
};

export enum TRANSACTION_TYPES {
  CASH_IN = "CASH_IN",
  CASH_OUT = "CASH_OUT",
}

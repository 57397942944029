import axios from "axios";
import toastify from "../../components/Toastify";
import { ERROR_MESSAGE } from "../constants/errors.constant";
import { removeEmpty } from "../utils/common.util";
import { getDeviceId, getToken, removeAllStorage } from "./storage.service";

import i18n from "../../i18n";

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_MIDOL_BACKEND_ENDPOINT + "/v1",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

class ApiService {
  private requestSubscribers: any[] = [];
  private isFetchingAccessToken = false;
  constructor() {
    axiosApiInstance.interceptors.request.use(
      (config) => {
        const accessToken = getToken();
        const deviceId = getDeviceId();

        config.headers = {
          Authorization: `JWT ${accessToken}`,
          "x-device-id": deviceId,
        };
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axiosApiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          ["TOKEN_EXPIRED", "INVALID_TOKEN", "SESSION_NOT_FOUND"].includes(
            error?.response?.data?.message
          )
        ) {
          removeAllStorage();
          window.location.href = "/dang-nhap";
        }
        return Promise.reject(error);
      }
    );
  }

  addRequestSubscriber(sub: any) {
    this.requestSubscribers.push(sub);
  }

  async retrySubscriberRequest(token: string) {
    for (const callback of this.requestSubscribers) {
      await callback(token);
    }

    this.requestSubscribers = [];
  }

  get(endpoint: string, params?: any, cancelToken?: any, config?: any) {
    return new Promise(async (resolve, reject) => {
      axiosApiInstance
        .get(endpoint, { ...config, params: removeEmpty(params), cancelToken })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this.handleError(error));
        });
    });
  }

  post(endpoint: string, _data?: any, params?: any) {
    return new Promise(async (resolve, reject) => {
      axiosApiInstance
        .post(endpoint, removeEmpty(_data), { params: removeEmpty(params) })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this.handleError(error));
        });
    });
  }

  put(endpoint: string, _data: any, allowRemoveEmpty = true) {
    return new Promise(async (resolve, reject) => {
      axiosApiInstance
        .put(endpoint, allowRemoveEmpty ? removeEmpty(_data) : _data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this.handleError(error));
        });
    });
  }

  delete(endpoint: string, _data: any) {
    return new Promise(async (resolve, reject) => {
      axiosApiInstance
        .delete(endpoint, _data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this.handleError(error));
        });
    });
  }

  patch(endpoint: string, _data: any) {
    return new Promise(async (resolve, reject) => {
      axiosApiInstance
        .patch(endpoint, _data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(this.handleError(error));
        });
    });
  }

  handleError(error: any) {
    if (axios.isCancel(error)) {
      return "";
    }
    if (error.response?.data?.statusCode === 429) {
      toastify({
        type: "error",
        msg: "You have exhausted your API Request Quota",
      });
      return new Error("You have exhausted your API Request Quota");
    }

    const { message, reason } = error.response.data;
    let errMsg = i18n.t(`ERROR_MESSAGES.${message}`) || ERROR_MESSAGE[reason || message];
    if (!message) {
      errMsg = ERROR_MESSAGE.INTERNAL_SERVER_ERROR;
    }
    toastify({
      type: "error",
      msg: errMsg,
    });
    return error.response.data.reason;
  }
}

export default new ApiService();

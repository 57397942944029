import * as React from 'react';
import './index.scss';

const StatisticCard = ({
  title,
  content,
  contentColor = '#532F91',
}: {
  title: string;
  content: string | React.ReactElement | number;
  contentColor?: string;
}) => {
  return (
    <div className="statistic-card-container">
      <span className="card-title">{title}</span>
      <span className="card-content" style={{ color: contentColor }}>
        {content}
      </span>
    </div>
  );
};

export default StatisticCard;

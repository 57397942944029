import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import bookingRequestAction from "./bookingRequest.action";

export interface BookingRequestState {
  data: Record<string, any>[];
  paginationMeta: Record<string, any>;
  totalSucceededRequests: number;
  totalFailedRequests: number;
  isLoading: boolean;
  error: string;
}

const initialState: BookingRequestState = {
  data: [],
  paginationMeta: {},
  totalFailedRequests: 0,
  totalSucceededRequests: 0,
  isLoading: false,
  error: '',
};

export const bookingRequestSlice = createSlice({
  name: "bookingRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bookingRequestAction.getRequestList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(bookingRequestAction.getRequestList.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const { result, paginationMeta, totalSucceededRequests, totalFailedRequests } = action.payload.data || {};

          state.data = result;
          state.paginationMeta = paginationMeta;
          state.totalSucceededRequests = totalSucceededRequests;
          state.totalFailedRequests = totalFailedRequests;
        }
      })
      .addCase(
        bookingRequestAction.getRequestList.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      )
  },
});

export const bookingRequestActions = bookingRequestSlice.actions;
export const bookingRequestSelector = (state: RootState) => state.bookingRequestReducer;
export default bookingRequestSlice.reducer;

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableComponent from '../../../components/Table';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import './index.scss';
import { InputAdornment, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import useDebounce from 'src/hooks/useDebounce';
import { useAppDispatch } from 'src/store/hooks';
import { usersAction } from 'src/store/users';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      // style={{ padding: "0 24px" }}
    >
      {value === index && <div className="user-tab-panel">{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type TabProps = {
  users: Record<string, any>[];
  isLoading: boolean;
  paginationMeta: any;
};

export enum USER_TYPE {
  IDOL = 'idol',
  FAN = 'fan',
}

export default function TabsComponent({ users, isLoading, paginationMeta }: TabProps) {
  const [value, setValue] = React.useState(0);
  const [inputSearch, setInputSearch] = React.useState('');
  const [isSortByFavorite, setIsSortByFavorite] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(20);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getUserType = (type: number) => {
    if (type === 0) {
      return USER_TYPE.FAN
    } else {
      return USER_TYPE.IDOL
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputSearch(value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const debouncedTextSearch = useDebounce(inputSearch, 500);

  React.useEffect(() => {
    dispatch(usersAction.getUsers({
      search: debouncedTextSearch,
      userType: getUserType(value),
      sortByFavorite: isSortByFavorite,
      page,
      offset,
    }));
    // eslint-disable-next-line
  }, [value, page, offset, debouncedTextSearch, isSortByFavorite]);

  const handleChangeStatus = (userId: any, isKol: boolean, status: any) => {
    if (userId) {
      dispatch(usersAction.updateUser({ userId, isKol, status }));
    }
  };

  const handleSetFavorite = (kolId: string, isFavorite: boolean) => {
    if (kolId) {
      dispatch(usersAction.setFavoriteKol({ kolId, isFavorite }));
    }
  };

  const handleSortByFavorite = () => {
    setIsSortByFavorite(!isSortByFavorite);
    setPage(1);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          padding: '0 24px',
          position: 'relative',
        }}
        className="user-tab-container"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="users-tab"
        >
          <Tab label={t('USER_PAGE.fanUser')} {...a11yProps(0)} />
          <Tab label={t('USER_PAGE.idolUser')} {...a11yProps(1)} />
        </Tabs>
        <FormGroup className="sort-favorite">
          <FormControlLabel 
            control={
              <Switch
                checked={isSortByFavorite}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={handleSortByFavorite}
              />
            } 
            label={t('USER_PAGE.filterByFavorite')}
            labelPlacement="start"
          />
        </FormGroup>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          onChange={handleInputChange}
          placeholder={t('searchPlaceholder')!}
          className="user-search"
          variant="standard"
          value={inputSearch}
        />
      </Box>

      <Box>
        <TabPanel value={value} index={0}>
          <TableComponent
            rows={users}
            page={page - 1}
            handleChangeStatus={handleChangeStatus}
            handleChangePage={handleChangePage}
            setOffset={setOffset}
            tab="fan"
            isLoading={isLoading}
            paginationMeta={paginationMeta}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <TableComponent
            rows={users}
            page={page - 1}
            handleChangeStatus={handleChangeStatus}
            handleSetFavorite={handleSetFavorite}
            handleChangePage={handleChangePage}
            setOffset={setOffset}
            tab="idol"
            isLoading={isLoading}
            paginationMeta={paginationMeta}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}

import apiService from "../../shared/services/api.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MIDOL_ENDPOINT } from "../../shared/constants/endpoints.constant";
import axios from "axios";

export type ITransactionUpdate = {
  kolId: string;
  walletId: string;
  transactionId: string;
  evidenceFile: string;
  status: string;
  amount: number;
  rejectedReason?: string;
};
class TransactionActions {
  getTransactionList = createAsyncThunk(
    "trans/getTransactionList",
    async (
      requestBody: {
        search: string;
        status: string;
        type: string;
        page: number;
        offset: number;
      },
      { rejectWithValue }
    ) => {
      try {
        const { page, offset, status, search, type } = requestBody;
        const res: any = await apiService.get(
          `${
            MIDOL_ENDPOINT.TRANSACTION.GET_TRANSACTION
          }?search=${encodeURIComponent(
            search
          )}&status=${status}&type=${type}&page=${page}&offset=${offset}`,
          {}
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  getTotalTransactions = createAsyncThunk(
    "trans/getTotalTransactions",
    async (requestBody: {}, { rejectWithValue }) => {
      try {
        const res: any = await apiService.get(
          MIDOL_ENDPOINT.TRANSACTION.GET_TOTAL_TRANSACTIONs,
          requestBody
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  updateTransaction = createAsyncThunk(
    "trans/updateTransaction",
    async (requestBody: ITransactionUpdate, { rejectWithValue }) => {
      try {
        const res: any = await apiService.put(
          MIDOL_ENDPOINT.TRANSACTION.UPDATE_TRANSACTION,
          requestBody
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  uploadEvidence = async (
    requestBody: { type: string; fileName: string; contentType: string },
    file: any
  ) => {
    try {
      // get path
      const presigned: any = await apiService.post(
        MIDOL_ENDPOINT.MEDIA.GET_PRESIGNED_URL,
        requestBody
      );

      const { url, pathname } = presigned.data.data;

      //upload file
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      return { url, pathname };
    } catch (error) {
      return null;
    }
  };
}

export default new TransactionActions();

const ERROR_MESSAGE: { [key: string]: string } = {
  USER_NOT_FOUND: 'Tài khoản không tồn tại',
  YOU_DONT_HAVE_ENOUGH_AGE: 'Bạn phải đủ 18 tuổi trở lên để tham gia vào Midol',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  SESSION_NOT_FOUND: 'SESSION_NOT_FOUND',
  EMAIL_INVALID: 'Email không hợp lệ',
  PHONE_NUMBER_INVALID: 'Số điện thoại không hợp lệ',
  KOL_EXISTED: 'Email idol đã tồn tại',
  PRICE_MAXIMUM_100000000: 'Giá video tối đa 100.000.000đ',
  KOL_NOT_FOUND: 'Tài khoản không tồn tại',
  EMAIL_NOT_VERIFIED: 'Email chưa xác thực',
  KOL_INACTIVE: 'Tài khoản không tồn tại',
  PASSWORD_INCORRECT: 'Tài khoản hoặc mật khẩu không hợp lệ',
  NEW_PASSWORD_DUPLICATE: 'Mật khẩu mới không được trùng với mật khẩu cũ',
  PASSWORD_REGEX_INVALID:
    'Mật khẩu ít nhất bao gồm 1 chữ hoa, 1 chữ thường, 1 kí tự đặc biệt và 1 chữ số',
  LOGIN_FAILED: 'Đăng nhập thất bại. Vui lòng thử lại!',
  PLEASE_CHOICE_AT_LEAST_ONE_CATEGORY: 'Vui lòng chọn ít nhất 1 thể loại!',
  PASSWORD_IS_REQUIRED: 'Mật khẩu  là bắt buộc',
  PASSWORD_AT_LEAST_6_CHARACTERS: 'Mật khẩu cũ phải tối thiểu 6 kí tự',
  PASSWORD_LESS_OR_EQUAL_50_CHARACTERS: 'Mật khẩu cũ phải tối đa 50 kí tự',
  PASSWORD_DOES_NOT_MATCH: 'Mật khẩu xác thực không khớp',
  AVATAR_SIZE_MAX_2_MB: 'Vui lòng úp hình tối đa 2 MB',
  VIDEO_SIZE_MAX_10_MB: 'Vui lòng úp video tối đa 10 MB',
  MAXIMUM_FAVORITE_KOL_ALLOWED: 'Đã đạt tối đa số lượng KOL nổi bật',
};

const ERROR_MESSAGE_NOT_SHOW_TOAST = ['RATE_LIMITED', 'TEXT_SEARCH_INVALID'];

export { ERROR_MESSAGE, ERROR_MESSAGE_NOT_SHOW_TOAST };

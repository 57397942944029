export enum BOOKING_REQUEST_STATUS {
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
  INITIAL = "INITIAL",
  PROCESSING = "PROCESSING",
}

export const PAYMENT_METHOD: any = {
  IC: "VISA",
  DC: "ATM",
  EW: "MOMO",
  QR: "VNPAY QR"
}

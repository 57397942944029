export enum BOOKING_REQUEST_STATUS {
  PROCESSING = "PROCESSING",
  SUCCEEDED = "SUCCEEDED",
}

export enum KOL_REQUEST_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  BLOCKED = 'blocked',
  KYC_APPROVED = 'kyc_approved',
  KYC_REJECTED = 'kyc_rejected',
}
import { createSlice } from "@reduxjs/toolkit";
import {
  removeAllStorage,
  setDeviceId,
  setRefreshToken,
  setToken,
} from "../../shared/services/storage.service";
import authActions from "../Auth/auth.action";
import { IUser } from "./auth.type";

export interface AuthState {
  isLoggined: boolean;
  isLoading: boolean;
  user: null | IUser;
}

const initialState: AuthState = {
  isLoggined: false,
  isLoading: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.isLoggined = false;
      state.isLoading = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.login.pending, (state) => {
        state.isLoading = true;
        state.isLoggined = false;
        state.user = null;
      })
      .addCase(authActions.login.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggined = false;
        state.user = null;
      })
      .addCase(authActions.login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggined = true;
        if (action.payload) {
          const { accessToken, refreshToken, deviceId, user } =
            action.payload.data;
          setToken(accessToken);
          setRefreshToken(refreshToken);
          setDeviceId(deviceId);
          state.user = user;
        }
      })

      .addCase(authActions.logout.pending, (state) => {
        state = { ...state, isLoading: true };
      })
      .addCase(authActions.logout.rejected, (state) => {
        state = { ...state, isLoading: false };
      })
      .addCase(authActions.logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggined = false;
        state.user = null;
        removeAllStorage();
      });
  },
});

export const { resetAuthState } = authSlice.actions;

export default authSlice.reducer;

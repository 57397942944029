export const removeEmpty = (obj: any): any => {
  if (!obj) return obj;
  const notNullOrUndefinedProp = Object.keys(obj).reduce((acc, key) => {
    if (obj[key] != null && obj[key] !== '') {
      acc = {
        ...acc,
        [key]: typeof obj[key] === 'string' ? obj[key].trim() : obj[key],
      };
    }
    return acc;
  }, {});
  return notNullOrUndefinedProp;
};

export const getMedia = (path: string) => {
  if (!path) return '';
  return `${process.env.REACT_APP_MIDOL_CDN_URL}${path}`;
};

export const separateNumber = function (
  number: number | string,
  characterSeparator = ','
) {
  return number
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, characterSeparator);
};

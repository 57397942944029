import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Modal, Paper, Zoom } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import IconButton from "@mui/material/IconButton";

interface Props {
  src: string;
  alt: string;
  width?: string;
  height?: string;
}

const StyledImage = styled("img")({
  cursor: "zoom-in",
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "contain",
});

const ImageViewer = ({ src, alt, width, height }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleZoomIn = () => setZoomLevel(zoomLevel + 0.1);
  const handleZoomOut = () => setZoomLevel(zoomLevel - 0.1);

  return (
    <>
      <Box onClick={handleOpenModal}>
        <StyledImage src={src} alt={alt} width={width} height={height} />
      </Box>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Zoom in={isModalOpen}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ p: 2 }}
          >
            <Paper sx={{ p: 2 }}>
              <Box
                sx={{
                  maxWidth: "100%",
                  maxHeight: "80vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <StyledImage
                  src={src}
                  alt={alt}
                  style={{ transform: `scale(${zoomLevel})` }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ mt: 2 }}
              >
                <Box sx={{ mr: 2 }}>
                  <div onClick={handleZoomIn}>
                    <IconButton aria-label="zoomIn" color="primary">
                      <ZoomInIcon />
                    </IconButton>
                  </div>
                </Box>
                <Box sx={{ mr: 2 }}>
                  <div onClick={handleZoomOut}>
                    <IconButton aria-label="zoomOut" color="primary">
                      <ZoomOutIcon />
                    </IconButton>
                  </div>
                </Box>
                <Box>
                  <div onClick={handleCloseModal}>
                    <IconButton aria-label="close" color="primary">
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
};

export default ImageViewer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../shared/services/api.service';
import { MIDOL_ENDPOINT } from '../../shared/constants/endpoints.constant';

const login = createAsyncThunk(
  MIDOL_ENDPOINT.ADMIN.LOGIN,
  async (requestBody: {}, { rejectWithValue }) => {
    try {
      const res: any = await apiService.post(
        MIDOL_ENDPOINT.ADMIN.LOGIN,
        requestBody
      );
      return res.data;
    } catch (error) {
      console.error('auth/admin/login err', error);
      return rejectWithValue(error);
    }
  }
);

const logout = createAsyncThunk(
  MIDOL_ENDPOINT.ADMIN.LOGOUT,
  async (requestBody: {}, { rejectWithValue }) => {
    try {
      const res: any = await apiService.post(
        MIDOL_ENDPOINT.ADMIN.LOGOUT,
        requestBody
      );
      return res.data;
    } catch (error) {
      console.error('auth/admin/logout err', error);
      return rejectWithValue(error);
    }
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default { login, logout };

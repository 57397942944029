import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TableComponent from '../Table';
import CashInTableComponent from '../CashInTable';
// import toastify from "src/components/Toastify";
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import useDebounce from 'src/hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import './index.scss';
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPES,
} from 'src/store/transaction/transaction.type';
import transactionAction from 'src/store/transaction/transaction.action';
import { transactionSelector } from 'src/store/transaction/transaction.slice';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="user-tab-panel">{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabsComponent() {
  const [value, setValue] = React.useState(0);
  const [inputSearch, setInputSearch] = React.useState('');
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(20);
  const [status, setStatus] = React.useState(
    TRANSACTION_STATUS.PROCESSING as string
  );
  const [type, setType] = React.useState(TRANSACTION_TYPES.CASH_IN as string);
  const { t } = useTranslation();

  const { transaction, isLoading } = useAppSelector(transactionSelector);
  const { rows } = transaction || {};

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
    if (newValue === 0) {
      handleChangeStatus(
        TRANSACTION_STATUS.PROCESSING,
        TRANSACTION_TYPES.CASH_IN
      );
    } else {
      handleChangeStatus(
        TRANSACTION_STATUS.PROCESSING,
        TRANSACTION_TYPES.CASH_OUT
      );
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputSearch(value);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const debouncedTextSearch = useDebounce(inputSearch, 500);

  const handleChangeStatus = (status: string, type: string) => {
    setStatus(status);
    setType(type);
  };

  React.useEffect(() => {
    dispatch(
      transactionAction.getTransactionList({
        search: debouncedTextSearch,
        status,
        type,
        page,
        offset,
      })
    );
    // eslint-disable-next-line
  }, [status, page, type, offset, debouncedTextSearch]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          padding: '0 24px',
          position: 'relative',
        }}
        className="user-tab-container"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="users-tab"
        >
          <Tab
            label={t('REVENUEEXPENDITURE_TABLE.revenueManagement')}
            {...a11yProps(0)}
          />
          <Tab
            label={t('REVENUEEXPENDITURE_TABLE.paymentRequest')}
            {...a11yProps(1)}
          />
        </Tabs>

        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          onChange={handleInputChange}
          placeholder={t('searchPlaceholder')!}
          className="user-search"
          variant="standard"
          value={inputSearch}
        />
      </Box>

      <Box>
        <TabPanel value={value} index={0}>
          <CashInTableComponent
            rows={rows}
            page={page - 1}
            handleChangePage={handleChangePage}
            setOffset={setOffset}
            isLoading={isLoading}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <div className="mt-4 mb-8" style={{ padding: '15px 25px' }}>
                <Button
                  className={`order-btn-wrap ${
                    status === TRANSACTION_STATUS.PROCESSING ? 'selected' : ''
                  }`}
                  variant="contained"
                  onClick={() =>
                    handleChangeStatus(
                      TRANSACTION_STATUS.PROCESSING,
                      'CASH_OUT'
                    )
                  }
                >
                  {t('REVENUEEXPENDITURE_TABLE.processing')}
                </Button>
                <Button
                  className={`order-btn-wrap ${
                    status === TRANSACTION_STATUS.SUCCEEDED ? 'selected' : ''
                  }`}
                  variant="contained"
                  style={{ marginLeft: '8px' }}
                  onClick={() =>
                    handleChangeStatus(TRANSACTION_STATUS.SUCCEEDED, 'CASH_OUT')
                  }
                >
                  {t('REVENUEEXPENDITURE_TABLE.completed')}
                </Button>
              </div>
            </Grid>
          </Grid>
          <TableComponent
            rows={rows}
            page={page - 1}
            handleChangePage={handleChangePage}
            setOffset={setOffset}
            buttonDisabled={true}
            tabStatus={status}
            isLoading={isLoading}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default React.memo(TabsComponent);

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import ProfilePending from './pages/ProfilePending';
import SignIn from './pages/SignIn';
import { PrivateRoute } from './components/PrivateRoute';
import Transaction from './pages/Transaction';
import { Suspense } from 'react';
import LoadingBackDrop from './components/LoadingBackDrop';
import Kyc from './pages/Kyc';
import BookingRequest from './pages/BookingRequest';

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={<LoadingBackDrop open={true} handleClose={() => {}} />}
        >
          <Layout>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/nguoi-dung"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ho-so-cho-duyet"
                element={
                  <PrivateRoute>
                    <ProfilePending />
                  </PrivateRoute>
                }
              />
              <Route
                path="/kyc"
                element={
                  <PrivateRoute>
                    <Kyc />
                  </PrivateRoute>
                }
              />
              <Route
                path="/thanh-toan"
                element={
                  <PrivateRoute>
                    <Transaction />
                  </PrivateRoute>
                }
              />
              <Route
                path="/quan-ly-yeu-cau"
                element={
                  <PrivateRoute>
                    <BookingRequest />
                  </PrivateRoute>
                }
              />
              <Route path="/dang-nhap" element={<SignIn />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>
          </Layout>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;

import * as React from "react";
import Typography from "@mui/material/Typography";
import "./index.scss";

interface TitleProps {
  children?: React.ReactNode;
  isSubTitle?: boolean;
  type?: string;
}

const Title = ({ children, isSubTitle = false, type }: TitleProps) => {
  const getClasses = () => {
    let classes = "";

    switch (type) {
      case "user-fan":
        classes = "title-primary";
        break;
      case "user-idol":
        classes = "title-secondary";
        break;

      default:
        break;
    }
    return classes;
  };

  return (
    <div className="custom-title">
      <Typography
        component="h2"
        variant="h6"
        // color="primary"
        gutterBottom
        className={isSubTitle ? "sub-title" : `title ${getClasses()}`}
      >
        {children}
      </Typography>
    </div>
  );
};

export default Title;

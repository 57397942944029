import { createTheme } from '@mui/material/styles';
import { buttonClasses } from '@mui/material';
import { red } from '@mui/material/colors';

const theme = createTheme({
  typography: {
    fontFamily: 'Manrope, sans-serif',
  },
  palette: {
    primary: {
      main: '#532F91',
    },
    secondary: {
      main: '#FFFFFF',
    },
    success: {
      main: '#00875A',
    },
    info: {
      main: '#767676',
    },
    action: {
      disabledBackground: '',
      disabled: '',
    },
    error: {
      main: red.A400, // TODO: change later
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.5,
          },
        },
      },
    },
  },
});

export default theme;

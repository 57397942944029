import React from "react";

type IProp = {
  className?: string;
  style?: any;
};
export default function SystemCheckCircle({ className, style }: IProp) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0522 3.14451C11.5749 2.48627 9.92437 2.3232 8.34682 2.67962C6.76927 3.03605 5.34919 3.89288 4.29839 5.12232C3.24758 6.35176 2.62235 7.88794 2.51593 9.50175C2.40952 11.1156 2.82763 12.7205 3.7079 14.0773C4.58817 15.4341 5.88344 16.47 7.40053 17.0305C8.91762 17.5909 10.5752 17.646 12.1262 17.1874C13.6771 16.7289 15.0383 15.7812 16.0067 14.4859C16.9751 13.1905 17.4988 11.6168 17.4997 9.99951V9.23332C17.4997 8.77309 17.8728 8.39999 18.333 8.39999C18.7932 8.39999 19.1663 8.77309 19.1663 9.23332V9.99999C19.1652 11.9767 18.5251 13.9006 17.3416 15.4838C16.158 17.067 14.4944 18.2252 12.5988 18.7857C10.7032 19.3462 8.67716 19.2789 6.82294 18.5938C4.96872 17.9088 3.38561 16.6427 2.30972 14.9844C1.23384 13.3262 0.722816 11.3645 0.852878 9.39209C0.98294 7.41965 1.74711 5.5421 3.03143 4.03945C4.31575 2.5368 6.05139 1.48957 7.97951 1.05393C9.90763 0.6183 11.9249 0.817608 13.7305 1.62213C14.1509 1.80945 14.3399 2.3021 14.1525 2.72249C13.9652 3.14288 13.4726 3.33183 13.0522 3.14451Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9216 2.74378C19.2472 3.06906 19.2475 3.59669 18.9222 3.92229L10.5889 12.264C10.4326 12.4204 10.2206 12.5083 9.99956 12.5083C9.77847 12.5084 9.56642 12.4206 9.41009 12.2643L6.91009 9.76426C6.58466 9.43882 6.58466 8.91118 6.91009 8.58574C7.23553 8.26031 7.76317 8.26031 8.0886 8.58574L9.99905 10.4962L17.7431 2.74437C18.0684 2.41877 18.596 2.41851 18.9216 2.74378Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

import StatisticCard from './StatisticCard';
import './index.scss';
import { useEffect, useState } from 'react';
import apiService from 'src/shared/services/api.service';
import { MIDOL_ENDPOINT } from 'src/shared/constants/endpoints.constant';
import { separateNumber } from 'src/shared/utils/common.util';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const [statistic, setStatistic] = useState({
    totalRevenue: 0,
    totalBookingRequests: 0,
    totalIncome: 0,
    totalAccounts: 0,
  });
  const { t } = useTranslation();

  const getStatistic = async () => {
    const {
      data: { data: statistic },
    }: any = await apiService.get(MIDOL_ENDPOINT.DASHBOARD.GET_STATISTIC);
    setStatistic(statistic);
  };

  useEffect(() => {
    getStatistic();
  }, []);

  return (
    <div className="cards-container">
      <StatisticCard
        title={t(`DASHBOARD_PAGE.totalRevenue`)}
        content={separateNumber(Math.floor(statistic.totalRevenue))}
      />
      <StatisticCard
        title={t(`DASHBOARD_PAGE.totalBookingRequest`)}
        content={separateNumber(Math.floor(statistic.totalBookingRequests))}
      />
      <StatisticCard
        title={t(`DASHBOARD_PAGE.totalIncome`)}
        content={separateNumber(Math.floor(statistic.totalIncome))}
        contentColor={'#0052CC'}
      />
      <StatisticCard
        title={t(`DASHBOARD_PAGE.totalUser`)}
        content={separateNumber(Math.floor(statistic.totalAccounts))}
        contentColor={'#000000'}
      />
    </div>
  );
};

export default Dashboard;

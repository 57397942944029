import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Title from "../../components/Title";
import { useAppSelector } from "../../store/hooks";
import { usersSelector } from "../../store/users";
import "./index.scss";
import TabsComponent from "./Tabs";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "16px 24px",
  gap: "8px",
  background: "#FFFFFF",
  borderRadius: "12px",
}));

const Users = () => {
  const { users, totalUsers, totalFan, totalIdol, isLoading, paginationMeta } =
    useAppSelector(usersSelector);
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
      }}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          columnSpacing={4}
          className="user-statistic"
        >
          <Grid item xs={12} md={4}>
            <Item className="statistic-item">
              <Title isSubTitle>{t("USER_PAGE.totalUser")}</Title>
              <Title>{totalUsers}</Title>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item className="statistic-item">
              <Title isSubTitle type="user-fan">
                {t("USER_PAGE.fanUser")}
              </Title>
              <Title type="user-fan">{totalFan}</Title>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item className="statistic-item">
              <Title isSubTitle type="user-idol">
                {t("USER_PAGE.idolUser")}
              </Title>
              <Title type="user-idol">{totalIdol}</Title>
            </Item>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          backgroundColor: "#fff",
          boxShadow:
            "0px 2px 4px rgba(50, 50, 50, 0.04), 0px 6px 12px rgba(50, 50, 50, 0.08)",
          borderRadius: "12px",
        }}
        sx={{ mt: 2 }}
      >
        <Grid item xs={12}>
          <TabsComponent users={users} isLoading={isLoading} paginationMeta={paginationMeta} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Users;

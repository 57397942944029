import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { TableHead } from '@mui/material';
import { separateNumber } from 'src/shared/utils/common.util';
import MuiPagination from 'src/components/MuiPagination';
import { useAppSelector } from 'src/store/hooks';
import { ReactComponent as TransactionConfirmSvg } from 'src/assets/icons/transaction-approve.svg';
import { ReactComponent as TransactionRejectSvg } from 'src/assets/icons/transaction-reject.svg';
import { format } from 'date-fns';
import ModalConfirm from '../ModalConfirm';
import { useAppDispatch } from 'src/store/hooks';
import transactionAction, {
  ITransactionUpdate,
} from 'src/store/transaction/transaction.action';
import toastify from 'src/components/Toastify';
import MuiModal from 'src/components/MuiModal';
import { ReactComponent as ModalRejectSvg } from 'src/assets/icons/modal-reject.svg';
import {
  transactionSelector,
  transactionSliceActions,
} from 'src/store/transaction/transaction.slice';
import { TRANSACTION_STATUS } from 'src/store/transaction/transaction.type';
import { useTranslation } from 'react-i18next';
import LoadingSkeleton from 'src/components/LoadingSkeleton';
import './index.scss';

const TableComponent = ({
  rows,
  page,
  handleChangePage,
  setOffset,
  tabStatus,
  isLoading
}: {
  rows: Record<string, any>[];
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  setOffset: (newOffset: number) => void;
  disableDetail?: boolean;
  buttonDisabled?: boolean;
  tabStatus?: string;
  isLoading?: boolean;
}) => {
  const { transaction, isUpdateSuccess, transactionStatus } =
    useAppSelector(transactionSelector);
  const [totalPage, setTotalPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalReject, setShowModalReject] = React.useState(false);
  const [currentTransaction, setCurrentTransaction]: any = React.useState(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  let TABLE_HEAD = [
    'requestPerson',
    'email',
    'phoneNumber',
    'bankInfo',
    'amount',
    'transferAmount',
    'requestDate',
  ];

  if (tabStatus === TRANSACTION_STATUS.SUCCEEDED) {
    TABLE_HEAD = [...TABLE_HEAD, 'processDate', 'status'];
  }
  if (tabStatus === TRANSACTION_STATUS.PROCESSING) {
    TABLE_HEAD = [...TABLE_HEAD, 'action'];
  }

  const toggleModal = () => setShowModal(!showModal);
  const toggleModalReject = () => setShowModalReject(!showModalReject);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(parseInt(event.target.value, 10));
  };
  const selectConfirm = (row: Record<string, any>, isReject?: boolean) => {
    setCurrentTransaction(row);
    isReject ? toggleModalReject() : toggleModal();
  };

  const handleApprove = async (presignedUrl: Record<string, any>) => {
    const { amount, wallet, bankInfo, _id } = currentTransaction || {};
    const data: ITransactionUpdate = {
      kolId: bankInfo.kol,
      walletId: wallet,
      transactionId: _id,
      evidenceFile: presignedUrl.pathname,
      status: 'SUCCEEDED',
      amount: amount,
      rejectedReason: '',
    };

    dispatch(transactionAction.updateTransaction(data));
  };

  const handleReject = () => {
    const { amount, wallet, bankInfo, _id } = currentTransaction || {};
    const data: ITransactionUpdate = {
      kolId: bankInfo.kol,
      walletId: wallet,
      transactionId: _id,
      evidenceFile: '',
      status: 'FAILED',
      amount: amount,
      rejectedReason: '',
    };

    dispatch(transactionAction.updateTransaction(data));
  };

  React.useEffect(() => {
    if (Object.keys(transaction).length) {
      const { perPage, totalPages } = transaction;
      setRowsPerPage(perPage);
      setTotalPage(totalPages);
    }
  }, [transaction]);

  React.useEffect(() => {
    if (isUpdateSuccess) {
      const msgType =
        transactionStatus === TRANSACTION_STATUS.SUCCEEDED
          ? 'success'
          : 'error';
      const content =
        transactionStatus === TRANSACTION_STATUS.SUCCEEDED
          ? t('TRANSACTION_PAGE.paymentSucceded')
          : t('TRANSACTION_PAGE.rejectedPayment');
      toastify({
        type: msgType,
        msg: content,
      });
      transactionStatus === TRANSACTION_STATUS.SUCCEEDED
        ? toggleModal()
        : toggleModalReject();
      dispatch(transactionSliceActions.resetState());
      dispatch(transactionAction.getTotalTransactions({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess, transactionStatus]);

  return (
    <div className="table-wrap">
      <TableContainer className="table-container">
        <Table
          sx={{ minWidth: 650, maxHeight: '460px' }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow className="profile-table-head">
              {TABLE_HEAD.map((item: string, index: number) => (
                <TableCell key={`${item}-${index}`} className="profile-cell">
                  {item ? t(`TRANSACTION_PAGE.${item}`) : item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {
            isLoading ? (
              <LoadingSkeleton itemColumn={TABLE_HEAD.length} itemRow={4} />
            ) : (
              <TableBody className="profile-table-body">
                {rows.map((row: Record<string, any>, index: number) => {
                  const {
                    _id,
                    kolPhoneNumber,
                    kolFullName,
                    kolEmail,
                    bankInfo,
                    amount,
                    createdAt,
                    updatedAt,
                    status,
                    transferAmount,
                  } = row || {};
                  return (
                    <TableRow
                      key={_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" scope="row">
                        <div className="user-info">
                          <div className="user-name">{kolFullName}</div>
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div className="user-info">
                          <div className="user-email">{kolEmail}</div>
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div className="user-type" style={{ color: '#000' }}>
                          {kolPhoneNumber}
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div>{bankInfo ? bankInfo.name : ''}</div>
                        <div>{bankInfo ? bankInfo.accountNumber : ''}</div>
                        <div>{bankInfo ? bankInfo.cardHolderName : ''}</div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div className="user-type" style={{ color: '#000' }}>
                          {`${separateNumber(amount)} đ`}
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div className="user-type" style={{ color: '#000' }}>
                          {`${separateNumber(transferAmount || 0) || 'N/A'} đ`}
                        </div>
                      </TableCell>
                      <TableCell align="left" scope="row">
                        <div>{format(new Date(createdAt), 'dd/MM/yyyy')}</div>
                      </TableCell>
                      {tabStatus === TRANSACTION_STATUS.SUCCEEDED ? (
                        <TableCell align="left" scope="row">
                          <div>{format(new Date(updatedAt), 'dd/MM/yyyy')}</div>
                        </TableCell>
                      ) : null}
                      <TableCell align="left" scope="row">
                        {tabStatus === TRANSACTION_STATUS.SUCCEEDED ? (
                          <>
                            {status === TRANSACTION_STATUS.SUCCEEDED ? (
                              <div className="transaction-approve">
                                {t('approve')}
                              </div>
                            ) : (
                              <div className="transaction-reject">
                                {t('reject')}
                              </div>
                            )}
                          </>
                        ) : (
                          <div style={{ cursor: 'pointer' }}>
                            <TransactionRejectSvg
                              style={{ marginRight: '24px' }}
                              onClick={() => selectConfirm(row, true)}
                            />
                            <TransactionConfirmSvg
                              onClick={() => selectConfirm(row)}
                            />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {
                  !rows.length && (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className="user-row"
                    >
                      <TableCell colSpan={TABLE_HEAD.length} align='center'>{t("noData")}</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            )
          } 
        </Table>
      </TableContainer>

      {!isLoading && rows?.length ? (
        <MuiPagination
          count={totalPage}
          page={page}
          handleChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}

      {showModal && (
        <ModalConfirm
          open={showModal}
          toggleModal={toggleModal}
          handleApprove={handleApprove}
        />
      )}

      {showModalReject && (
        <MuiModal
          open={showModalReject}
          titleCancel="Trở lại"
          titleSubmit="Xác nhận từ chối"
          onClose={toggleModalReject}
          onSubmit={handleReject}
          onCancel={toggleModalReject}
          maxHeight="324px"
          isHideCloseIcon
        >
          <div className="payment-center">
            <ModalRejectSvg />
            <div className="payment-modal-title">
              {t('TRANSACTION_PAGE.confirmRejectPayment')}
            </div>
            <div className="payment-modal-sub">
              {t('TRANSACTION_PAGE.confirmRejectPaymentDesc')}
            </div>
          </div>
        </MuiModal>
      )}
    </div>
  );
};

export default React.memo(TableComponent);

import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const ReadMore = ({ children, textSize = 100 }: any) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const { t } = useTranslation();
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text" style={{ display: "inline", width: "100%", overflowWrap: "break-word" }}>
      {text && text.length && isReadMore ? text.slice(0, textSize) : text}
      <span onClick={toggleReadMore} className="read-or-hide" style={{ cursor: "pointer" }}>
        {text && text.length > textSize ? (
          isReadMore ? (
            <span>
              ...<span style={{ fontWeight: 600 }}>{t('COMMON.readmore')}</span>
            </span>
          ) : (
            <span style={{ fontWeight: 600 }}> {t('COMMON.readless')}</span>
          )
        ) : null}
      </span>
    </p>
  );
};

const ReadMoreText = ({ children, ...props }: any) => {
  return <ReadMore {...props}>{children}</ReadMore>;
};

export default ReadMoreText;

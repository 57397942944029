import { createSlice, current } from "@reduxjs/toolkit";
import transactionAction from "./transaction.action";
import { RootState } from "../index";

export interface ITransactionState {
  transaction: {
    perPage: number;
    totalPages: number;
    totalRows: number;
    rows: Record<string, any>[];
  };
  total: Record<string, any>;
  isLoading: boolean;
  isUpdateSuccess?: boolean;
  transactionStatus?: string;
  error: string;
}

const initialState: ITransactionState = {
  transaction: {
    perPage: 20,
    totalPages: 0,
    totalRows: 0,
    rows: [],
  },
  transactionStatus: "",
  total: {},
  isLoading: false,
  isUpdateSuccess: false,
  error: "",
};

export const transactionSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isUpdateSuccess = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(transactionAction.getTransactionList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        transactionAction.getTransactionList.fulfilled,
        (state, action) => {
          if (action.payload) {
            state.isLoading = false;
            state.transaction = action.payload.data;
          }
        }
      )
      .addCase(
        transactionAction.getTransactionList.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      )

      .addCase(transactionAction.getTotalTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        transactionAction.getTotalTransactions.fulfilled,
        (state, action) => {
          if (action.payload) {
            state.isLoading = false;
            state.total = action.payload.data;
          }
        }
      )
      .addCase(
        transactionAction.getTotalTransactions.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      )

      .addCase(transactionAction.updateTransaction.pending, (state) => {
        state.isLoading = true;
        state.isUpdateSuccess = false;
      })
      .addCase(
        transactionAction.updateTransaction.fulfilled,
        (state, action) => {
          if (action.payload) {
            const rawData = current(state.transaction);
            state.transaction.rows = rawData.rows.filter(
              (item) => item._id !== action.payload.data._id
            );
            state.transactionStatus = action.payload.data.status;
            state.isUpdateSuccess = true;
            state.isLoading = false;
          }
        }
      )
      .addCase(
        transactionAction.updateTransaction.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      );
  },
});

export const transactionSliceActions = transactionSlice.actions;
export const transactionSelector = (state: RootState) =>
  state.transactionReducer;
export default transactionSlice.reducer;

import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import authReducer from "./Auth/auth.slice";
import usersReducer from "./users/users.slice";
import requestReducer from "./request/request.slice";
import globalReducer from "./global/global.slice";
import transactionReducer from "./transaction/transaction.slice";
import bookingRequestReducer from "./bookingRequest/bookingRequest.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  usersReducer: usersReducer,
  requestReducer: requestReducer,
  globalReducer: globalReducer,
  transactionReducer: transactionReducer,
  bookingRequestReducer: bookingRequestReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ['auth', 'globalReducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export default store;

export const persistor = persistStore(store);

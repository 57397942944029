import React from 'react';
import { Box, MenuItem, Pagination, Select } from '@mui/material';
import './index.scss';
import { useTranslation } from 'react-i18next';

export interface FormPaginationProps {
  count: number;
  page: number;
  handleChange: any;
  rowsPerPage: any;
  handleChangeRowsPerPage: any;
}

const MuiPagination = ({
  count,
  page,
  handleChange,
  rowsPerPage,
  handleChangeRowsPerPage,
}: FormPaginationProps) => {
  const { t } = useTranslation();
  const rowPerPage = ['20', '50', '100'];

  return (
    <Box className="pagination-wrap">
      <Pagination
        count={count}
        page={page + 1}
        onChange={handleChange}
        // color="#000"
        shape="rounded"
        className="user-pagination-item pagination-text"
        classes={{ text: 'pagination-text' }}
      />

      <Box className="pagination-text">
        {t('display')}
        <Select
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          className="pagination-selector pagination-text"
        >
          {rowPerPage.map((item) => (
            <MenuItem key={item} value={item} className="pagination-text">
              {item} {t('rows')}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default MuiPagination;

import apiService from "../../shared/services/api.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MIDOL_ENDPOINT } from "../../shared/constants/endpoints.constant";

class UserActions {
  getUsers = createAsyncThunk(
    "users/getUsers",
    async (requestBody: {
      search: string;
      userType: string;
      sortByFavorite: boolean;
      page: number;
      offset: number;
    }, { rejectWithValue }) => {
      try {
        const res: any = await apiService.get(
          MIDOL_ENDPOINT.USERS.GET_USERS,
          requestBody
        );
        return res.data;
      } catch (error) {
        console.error("users/getUsers err", error);
        return rejectWithValue(error);
      }
    }
  );

  updateUser = createAsyncThunk(
    "users/updateUserStatus",
    async (requestBody: {}, { rejectWithValue }) => {
      try {
        const res: any = await apiService.put(
          MIDOL_ENDPOINT.USERS.UPDATE_USER,
          requestBody
        );
        return res.data;
      } catch (error) {
        console.error("users/updateUserStatus err", error);
        return rejectWithValue(error);
      }
    }
  );

  setFavoriteKol = createAsyncThunk(
    "users/setFavoriteKol",
    async (requestBody: {}, { rejectWithValue }) => {
      try {
        const res: any = await apiService.put(
          MIDOL_ENDPOINT.USERS.SET_FAVORITE_KOL,
          requestBody
        );
        return res.data;
      } catch (error) {
        console.error("users/setFavoriteKol err", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default new UserActions();

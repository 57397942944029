import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { memo } from "react";
import "./index.scss";

const MuiModal = ({
  open,
  onClose,
  title,
  children,
  onSubmit,
  onCancel,
  titleSubmit,
  titleCancel,
  disabled = false,
  maxHeight,
  isHideCloseIcon,
}: any) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "560px",
    background: "#fff",
    borderRadius: 3,
    padding: 3,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    // height: "100%",
    maxHeight: maxHeight || "796px",
    outline: "none",
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        width: "100%",
        height: "100%",
        background: "rgba(255 255 255 / 0.5)",
        border: "none",
      }}
    >
      <Box sx={style} className="midol-modal">
        {!isHideCloseIcon && (
          <Box>
            <div
              className="flex w-full justify-end"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                border: "none",
              }}
            >
              <div
                onClick={onClose}
                style={{
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </div>
            </div>

            <div className="modal-content-center mt-2 mb-2">
              <span className="midol-title text-center">{title}</span>
            </div>
          </Box>
        )}

        <div className="midol-modal-wrap">{children}</div>

        <div className="flex flex-row modal-container">
          {titleCancel && (
            <div className="mt-8 w-full">
              <Button
                // disabled={disabled}
                variant="contained"
                onClick={onCancel}
                className="midol-modal-btn midol-secondary-btn w-full"
              >
                {titleCancel}
              </Button>
            </div>
          )}
          {titleSubmit && (
            <div className="mt-8 w-full">
              <Button
                disabled={disabled}
                variant="contained"
                onClick={onSubmit}
                className="midol-modal-btn w-full"
              >
                {titleSubmit}
              </Button>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default memo(MuiModal);

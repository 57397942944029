import * as React from 'react';
import Table from '@mui/material/Table';
import parse from 'html-react-parser';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  TableHead,
  Toolbar,
  Typography,
} from '@mui/material';
import { ReactComponent as MoreIcon } from 'src/assets/icons/more-vertical.svg';
import './index.scss';
import { getMedia } from 'src/shared/utils/common.util';
import MuiPagination from '../MuiPagination';
import { useTranslation } from 'react-i18next';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { KOL_REQUEST_STATUS } from 'src/store/request/request.type';
import LoadingSkeleton from '../LoadingSkeleton';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TableComponent({
  rows,
  page,
  handleChangePage,
  setOffset,
  handleChangeStatus,
  handleSetFavorite,
  tab,
  isLoading,
  paginationMeta
}: {
  rows: Record<string, any>[];
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  setOffset: (newOffset: number) => void;
  handleChangeStatus: any;
  handleSetFavorite?: any;
  tab: 'idol' | 'fan' | 'all';
  isLoading?: boolean;
  paginationMeta?: any;
}) {
  const [totalPage, setTotalPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { t } = useTranslation();
  const [currentRowId, setCurrentRowId] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [TCVersion, setTCVersion] = React.useState('');
  const [TCContent, setTCContent] = React.useState('');
  const [selectedUserType, setSelectedUserType] = React.useState<
    'idol' | 'fan' | 'all'
  >('all');

  const handleClickOpenTC = ({
    version,
    content,
    userType,
  }: {
    version: string;
    content: string;
    userType: 'idol' | 'fan' | 'all';
  }) => {
    setTCVersion(version);
    setTCContent(content);
    setSelectedUserType(userType);
    setOpen(true);
  };

  const handleCloseTC = () => {
    setOpen(false);
  };

  const TABLE_HEAD = [
    'nameAndEmail',
    'userId',
    'type',
    'status',
    'favoriteUser',
    'phoneNumber',
    'viewTC',
    'referer',
    '',
  ];

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (Object.keys(paginationMeta).length) {
      const { perPage, totalPages } = paginationMeta;
      setRowsPerPage(perPage);
      setTotalPage(totalPages);
    }
  }, [paginationMeta]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(parseInt(event.target.value, 10));
  };

  const _handleChangeStatus = async (event: any) => {
    const userId: any = event.target.getAttribute('data-id');
    const userType: any = event.target.getAttribute('data-type');
    const userStatus: any = event.target.getAttribute('data-status');

    await handleChangeStatus(
      userId,
      userType === 'idol' ? true : false,
      userStatus !== KOL_REQUEST_STATUS.BLOCKED ? KOL_REQUEST_STATUS.BLOCKED : KOL_REQUEST_STATUS.ACTIVE,
    );
    handleClose();
  };

  const _handleSetFavorite = async (kolId: string, isFavorite: boolean) => {
    if (kolId) {
      await handleSetFavorite(kolId, isFavorite);
    }
  };

  const renderColumnHeaderTitle = (item: string) => {
    if (!item) {
      return item;
    }
    if (item === 'viewTC') {
      if (tab === 'all') {
        return t(`USER_TABLE_HEADER_COLUMNS.viewTC`);
      }
      if (tab === 'fan') {
        return t(`USER_TABLE_HEADER_COLUMNS.fanToS`);
      }
      if (tab === 'idol') {
        return t(`USER_TABLE_HEADER_COLUMNS.kolT&C`);
      }
    }
    // if (item === 'referer') {
    //   if (tab === 'fan') {
    //     return null;
    //   }
    // }
    return t(`USER_TABLE_HEADER_COLUMNS.${item}`);
  };

  const renderAppBarTitle = (version: string) => {
    if (selectedUserType === 'fan') {
      return `${t(`USER_TABLE_HEADER_COLUMNS.fanToS`)} version ${version}`;
    }
    if (selectedUserType === 'idol') {
      return `${t(`USER_TABLE_HEADER_COLUMNS.kolT&C`)} version ${version}`;
    }
    return `${t(`USER_TABLE_HEADER_COLUMNS.viewTC`)} version ${version}`;
  };

  return (
    <div className="table-wrap">
      <TableContainer className="table-container">
        <Table
          sx={{ minWidth: 650, maxHeight: '460px' }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow className="profile-table-head">
              {TABLE_HEAD.map((item: string, index: number) => (
                <TableCell key={`${item}-${index}`} className="profile-cell">
                  {renderColumnHeaderTitle(item)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {
            isLoading ? (
              <LoadingSkeleton itemColumn={TABLE_HEAD.length} itemRow={4} />
            ) : (
              <TableBody>
                {rows?.map((row: Record<string, any>) => {
                    const {
                      _id,
                      status,
                      phoneNumber,
                      email,
                      fullName,
                      userType,
                      avatar,
                      agreeTC: { version = '', content = '' } = {},
                      referer,
                      isFavorite = false,
                      nickName,
                      userStatus,
                    } = row || {};
                    return (
                      <TableRow
                        key={_id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className="user-row"
                      >
                        <TableCell align="left" scope="row" className="user-cell">
                          <div className="user-info">
                            <div className="user-avatar">
                              <Avatar
                                sx={{ height: 36, width: 36 }}
                                src={getMedia(avatar)}
                              />
                            </div>
                            <div>
                              <div className="user-name">{`${fullName} ${nickName ? `(${nickName})` : ``}`}</div>
                              <div className="user-email">{email}</div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <div className="user-id">{_id}</div>
                        </TableCell>
                        <TableCell align="left">
                          <div
                            className={`user-type-wrap ${
                              userType === 'idol' ? 'user-idol' : ''
                            }`}
                          >
                            <div className="user-type">{userType}</div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={`user-status`}>
                            <span
                              className={`${!userStatus ? 'active' : userStatus}`}
                            >
                              {t(`USER_STATUS.${!userStatus ? 'active' : userStatus}`)}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={`user-favorite`}>
                            <span
                              className={`${userStatus === 'active' ? 'active' : null}`}
                            >
                              {userType === 'idol' && (
                                <Switch
                                  checked={isFavorite}
                                  onChange={() =>
                                    _handleSetFavorite(_id, !isFavorite)
                                  }
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              )}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <span>{phoneNumber}</span>
                        </TableCell>
                        <TableCell>
                          <span
                            className="version-link"
                            onClick={() =>
                              handleClickOpenTC({ version, content, userType })
                            }
                          >
                            {version && `Version ${version}`}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>{referer}</span>
                        </TableCell>
                        <TableCell align="left">
                          <div className="user-action">
                            {status !== 'rejected' && (
                              <>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl) && _id === currentRowId}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  <MenuItem
                                    onClick={_handleChangeStatus}
                                    data-id={_id}
                                    data-type={userType}
                                    data-status={userStatus}
                                    className={`user-menu-item ${
                                      userStatus !== 'active' ? 'active' : null
                                    }`}
                                  >
                                    {t(`USER_UPDATE_ACTION.${!userStatus ? 'active' : userStatus}`)}
                                  </MenuItem>
                                </Menu>

                                <Button
                                  variant="text"
                                  onClick={(event) => handleClick(event, _id)}
                                >
                                  <MoreIcon />
                                </Button>
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {
                    !rows.length && (
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className="user-row"
                      >
                        <TableCell colSpan={TABLE_HEAD.length} align='center'>{t("noData")}</TableCell>
                      </TableRow>
                    )
                  }
              </TableBody>
            )
          }
        </Table>
      </TableContainer>

      {!isLoading && paginationMeta && (
        <MuiPagination
          count={totalPage}
          page={page}
          handleChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleCloseTC}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ background: 'white' }} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="default"
              onClick={handleCloseTC}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: 'black' }}
              variant="h6"
              component="div"
            >
              {renderAppBarTitle(TCVersion)}
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ maxWidth: 768, margin: '0 auto', padding: '100px 0px' }}>
          {parse(TCContent)}
        </div>
      </Dialog>
    </div>
  );
}

import * as React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import ReactPlayer from "react-player";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import InfoIcon from "@mui/icons-material/Info";
import { Button, TableHead, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { separateNumber, getMedia } from "src/shared/utils/common.util";
import MuiPagination from "src/components/MuiPagination";
import { useAppSelector } from "src/store/hooks";
import { bookingRequestSelector } from "src/store/bookingRequest/bookingRequest.slice";
import { truncateText } from "src/shared/utils/helpers";
import MuiModal from "src/components/MuiModal";
import {
  BOOKING_REQUEST_STATUS,
  PAYMENT_METHOD,
} from "src/store/bookingRequest/bookingRequest.type";
import "./index.scss";
import LoadingSkeleton from "src/components/LoadingSkeleton";

const TableComponent = ({
  rows,
  page,
  handleChangePage,
  setOffset,
  disableDetail = false,
  tabName,
}: {
  rows: Record<string, any>[];
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  setOffset: (newOffset: number) => void;
  tabName: string;
  disableDetail?: boolean;
  buttonDisabled?: boolean;
}) => {
  const { paginationMeta, isLoading } = useAppSelector(bookingRequestSelector);
  const [totalPage, setTotalPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [video, setVideo] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();

  const TABLE_HEAD = [
    "requestId",
    "fanName",
    "kolName",
    "bookingPrice",
    "createdDate",
    "requestContent",
    "requestVideo",
    "paymentMethod",
  ];

  const listTableHead: string[] =
    tabName !== BOOKING_REQUEST_STATUS.FAILED
      ? [...TABLE_HEAD]
      : [...TABLE_HEAD, "paymentStatus", "updatedDate"];

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(parseInt(event.target.value, 10));
  };

  React.useEffect(() => {
    if (Object.keys(paginationMeta).length) {
      const { perPage, totalPages } = paginationMeta;

      setRowsPerPage(perPage);
      setTotalPage(totalPages);
    }
  }, [paginationMeta]);

  const handleViewVideo = (videoUrl: string) => {
    setVideo(videoUrl);
    setShowModal(true);
  };

  return (
    <div className="table-wrap">
      <TableContainer className="table-container">
        <Table
          sx={{ minWidth: 650, maxHeight: "460px" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow className="profile-table-head">
              {listTableHead.map((item: string, index: number) => (
                <TableCell key={`${item}-${index}`} className="profile-cell">
                  {item
                    ? t(`BOOKING_REQUEST_TABLE_HEADER_COLUMNS.${item}`)
                    : item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
            {
              isLoading ? (
                <LoadingSkeleton itemColumn={listTableHead.length} itemRow={4} />
              ) : (
                <TableBody className="profile-table-body">
                  {rows?.map((row: Record<string, any>, _index: number) => {
                    const {
                      _id,
                      invoiceNo,
                      idolName,
                      fanName,
                      transactionPrice,
                      createdAt,
                      updatedAt,
                      content,
                      videoUrl,
                      paymentMethod,
                      isFirstTimePromoApplied,
                      paymentStatus,
                      refundStatus,
                    } = row || {};

                    return (
                      <TableRow
                        key={_id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="left" scope="row">
                          <div className="transaction-id">{invoiceNo}</div>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <div className="user-name">{fanName}</div>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <div className="user-name">{idolName}</div>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <div
                            className="price"
                            style={{
                              color: "#000",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {`${separateNumber(transactionPrice as number)} đ`}
                            <Tooltip
                              title={
                                isFirstTimePromoApplied
                                  ? t(
                                      `BOOKING_REQUEST_TABLE_HEADER_COLUMNS.priceWithFirstPromoDetails`
                                    )
                                  : (t(
                                      `BOOKING_REQUEST_TABLE_HEADER_COLUMNS.priceDetails`
                                    ) as any)
                              }
                            >
                              <InfoIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginLeft: "7px",
                                }}
                              />
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <div className="createdAt">
                            {moment(createdAt).format("DD-MM-YYYY")}
                          </div>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <Tooltip title={content || ""} followCursor>
                            <div className="content">
                              {truncateText(content, 30)}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <div className="video">
                            {videoUrl && (
                              <Button
                                variant="text"
                                onClick={() => handleViewVideo(videoUrl)}
                              >
                                <VisibilityIcon style={{ color: "black" }} />
                              </Button>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <div className="payment-method">
                            {PAYMENT_METHOD[paymentMethod] ?? ""}
                          </div>
                        </TableCell>
                        {tabName === BOOKING_REQUEST_STATUS.FAILED && (
                          <>
                            <TableCell align="left" scope="row">
                              <Tooltip title={`Amount: ${refundStatus?.amount} - Message: ${refundStatus?.message}`}>
                                <div className="paymenStatus">
                                  {paymentStatus && paymentStatus?.toUpperCase()?.includes('REFUND') ? t(`BOOKING_REQUEST_TABLE_HEADER_COLUMNS.${paymentStatus}`) : ''}
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left" scope="row">
                              <div className="updatedAt">
                                {moment(updatedAt).format("DD-MM-YYYY")}
                              </div>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                  {
                    !rows.length && (
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        className="user-row"
                      >
                        <TableCell colSpan={TABLE_HEAD.length} align='center'>{t("noData")}</TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              )
            }
        </Table>
      </TableContainer>

      {!isLoading && rows?.length && paginationMeta ? (
        <MuiPagination
          count={totalPage}
          page={page}
          handleChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}

      {showModal && (
        <MuiModal open={showModal} onClose={() => setShowModal(false)}>
          <ReactPlayer
            pip={true}
            width={"100%"}
            url={getMedia(video)}
            light={false}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            controls={true}
          />
        </MuiModal>
      )}
    </div>
  );
};

export default React.memo(TableComponent);

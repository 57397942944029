import { useMemo } from "react";
import { toast } from "react-toastify";

import style from "./Toastify.module.scss";
import SystemError from "../../shared/Icons/SystemError";
import SystemClose from "../../shared/Icons/SystemClose";
import SystemCheckCircle from "../../shared/Icons/SystemCheckCirle";

type Props = {
  type: "success" | "info" | "error" | "warning" | "dark" | "warn";
  msg?: string | any;
  msgBtn?: string;
  extraMsg?: string;
  onClick?: () => void;
  onClose?: () => void;
  autoClose?: number;
};

function Toastify({ type, msg }: Props) {
  const iconName = useMemo(() => {
    switch (type) {
      case "info":
      // return (
      //   <SystemInfo
      //     style={{
      //       color: "#4B9C95",
      //     }}
      //   />
      // );
      // eslint-disable-next-line no-fallthrough
      case "success":
        return (
          <SystemCheckCircle
            style={{
              color: "#006644",
            }}
          />
        );
      case "error":
        return (
          <SystemError
            style={{
              color: "#ff4a32",
            }}
          />
        );
      case "warning":
      case "warn":
      // return (
      //   <SystemWarning
      //     style={{
      //       color: "#edca1e",
      //     }}
      //   />
      // );

      // eslint-disable-next-line no-fallthrough
      default:
        break;
    }
  }, [type]);

  return (
    <div className={`${style["toastify-container"]}`}>
      {iconName}
      <p className={style["toastify-content"]}>{msg} </p>
    </div>
  );
}
export default function toastify({
  type,
  msg,
  onClose,
  msgBtn,
  onClick,
  extraMsg,
  autoClose = 2000,
}: Props) {
  toast[type](
    <Toastify
      type={type}
      msg={msg}
      msgBtn={msgBtn}
      extraMsg={extraMsg}
      onClick={onClick}
    />,
    {
      position: "top-center",
      autoClose: autoClose,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose,
      icon: false,
      closeButton: () => (
        <div
          style={{
            width: "24px",
            height: "24px",
          }}
        >
          <SystemClose />
        </div>
      ),
    }
  );
}

import React from "react";

export default function SystemError({ style, size = 24, isCurrentColor = false }: any) {
  return (
    <svg
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_65_6132"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="24"
        height="21"
      >
        <path
          d="M3.50035 22C3.00035 22 2.50035 21.9 2.00035 21.6C0.600353 20.8 0.100353 18.9 0.900353 17.5L9.40035 3.29999C9.70035 2.89999 10.0004 2.49999 10.4004 2.29999C11.1004 1.89999 11.9004 1.79999 12.7004 1.99999C13.5004 2.19999 14.1004 2.69999 14.6004 3.39999L23.0004 17.5C23.3004 18 23.4004 18.5 23.4004 19C23.4004 19.8 23.1004 20.6 22.5004 21.1C22.0004 21.7 21.3004 22 20.5004 22H3.50035ZM11.1004 4.39999L2.70035 18.5C2.40035 19 2.60035 19.6 3.10035 19.9C3.20035 20 3.40035 20 3.50035 20H20.4004C20.7004 20 20.9004 19.9 21.1004 19.7C21.3004 19.5 21.4004 19.3 21.4004 19C21.4004 18.8 21.4004 18.7 21.3004 18.5L12.9004 4.39999C12.6004 3.89999 12.0004 3.79999 11.5004 3.99999C11.3004 4.09999 11.2004 4.19999 11.1004 4.39999Z"
          fill="#061938"
        />
        <path
          d="M12.0004 14C11.4004 14 11.0004 13.6 11.0004 13V8.99999C11.0004 8.39999 11.4004 7.99999 12.0004 7.99999C12.6004 7.99999 13.0004 8.39999 13.0004 8.99999V13C13.0004 13.6 12.6004 14 12.0004 14Z"
          fill="#061938"
        />
        <path
          d="M12.0004 18C11.7004 18 11.5004 17.9 11.3004 17.7C11.1004 17.5 11.0004 17.3 11.0004 17C11.0004 16.9 11.0004 16.7 11.1004 16.6C11.2004 16.5 11.2004 16.4 11.3004 16.3C11.4004 16.2 11.5004 16.1 11.6004 16.1C11.8004 16 12.0004 16 12.2004 16C12.3004 16 12.3004 16 12.4004 16.1C12.5004 16.1 12.5004 16.1 12.6004 16.2C12.6004 16.2 12.7004 16.3 12.8004 16.3C12.9004 16.4 13.0004 16.5 13.0004 16.6C13.0004 16.7 13.1004 16.9 13.1004 17C13.1004 17.3 13.0004 17.5 12.8004 17.7C12.5004 17.9 12.3004 18 12.0004 18Z"
          fill="#061938"
        />
      </mask>
      <g mask="url(#mask0_65_6132)">
        <rect width="24" height="24" fill="currentColor" />
      </g>
    </svg>
  );
}

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title';
import { useAppSelector } from '../../store/hooks';
import TabsComponent from './Tabs';
import './index.scss';
import { requestSelector } from 'src/store/request/request.slice';
import { useTranslation } from 'react-i18next';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '16px 24px',
  gap: '8px',
  background: '#FFFFFF',
  borderRadius: '12px',
}));

const ProfilePending = () => {
  const { totalRequests, totalTodayRequests } = useAppSelector(requestSelector);
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
      }}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          columnSpacing={4}
          className="user-statistic"
        >
          <Grid item xs={12} md={6}>
            <Item className="statistic-item" sx={{ height: '100px' }}>
              <Title isSubTitle>{t('PROFILE_PAGE.totalProfile')}</Title>
              <Title>{totalRequests}</Title>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item className="statistic-item" sx={{ height: '100px' }}>
              <Title isSubTitle type="user-fan">
                {t('PROFILE_PAGE.today')}
              </Title>
              <Title>{totalTodayRequests}</Title>
            </Item>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          backgroundColor: '#fff',
          boxShadow:
            '0px 2px 4px rgba(50, 50, 50, 0.04), 0px 6px 12px rgba(50, 50, 50, 0.08)',
          borderRadius: '12px',
        }}
        sx={{ mt: 2 }}
      >
        <Grid item xs={12}>
          <TabsComponent />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfilePending;

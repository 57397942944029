import apiService from "../../shared/services/api.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MIDOL_ENDPOINT } from "../../shared/constants/endpoints.constant";

type IApproveStatus = {
  kolId: string;
  approvedStatus: boolean;
  reason?: string;
};
class RequestActions {
  getRequestList = createAsyncThunk(
    "users/getRequestList",
    async (
      requestBody: {
        search: string;
        status: string;
        page: number;
        offset: number;
      },
      { rejectWithValue }
    ) => {
      try {
        const { page, offset, status, search } = requestBody;
        const res: any = await apiService.get(
          `${MIDOL_ENDPOINT.REQUEST.GET_REQUEST}?search=${encodeURIComponent(
            search
          )}&status=${status}&page=${page}&offset=${offset}`,
          {}
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  approveStatusRequest = createAsyncThunk(
    "users/approveStatusRequest",
    async (requestBody: IApproveStatus, { rejectWithValue }) => {
      try {
        const res: any = await apiService.put(
          MIDOL_ENDPOINT.REQUEST.APPROVE_STATUS,
          requestBody
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  getKycRequestList = createAsyncThunk(
    "users/getKycRequestList",
    async (
      requestBody: {
        search: string;
        page: number;
        offset: number;
        status: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const { page, offset, search, status } = requestBody;
        const res: any = await apiService.get(
          `${MIDOL_ENDPOINT.REQUEST.KYC_REQUEST}?search=${encodeURIComponent(
            search
          )}&status=${status}&page=${page}&offset=${offset}`,
          {}
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  approveKycRequest = createAsyncThunk(
    "users/approveKycRequest",
    async (requestBody: IApproveStatus, { rejectWithValue }) => {
      try {
        const res: any = await apiService.put(
          MIDOL_ENDPOINT.REQUEST.KYC_REQUEST,
          requestBody
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default new RequestActions();

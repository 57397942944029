import { KOL_REQUEST_STATUS } from "./request.type";
import { createSlice } from "@reduxjs/toolkit";
import requestAction from "./request.action";
import { RootState } from "../index";

export interface UsersState {
  requestsFailed: Record<string, any>[];
  requestsProcessing: Record<string, any>[];
  requestsApproved: Record<string, any>[];
  kycRequestsApproved: Record<string, any>[];
  kycRequestsFailed: Record<string, any>[];
  kycRequestsProcessing: Record<string, any>[];
  paginationMeta: Record<string, any>;
  totalRequests: number;
  totalTodayRequests: number;
  isLoading: boolean;
  error: string;
  isChangedStatus: boolean;
  kycTotalRequests: number;
  kycTotalTodayRequests: number;
}

const initialState: UsersState = {
  requestsFailed: [],
  requestsProcessing: [],
  requestsApproved: [],
  kycRequestsFailed: [],
  kycRequestsApproved: [],
  kycRequestsProcessing: [],
  kycTotalRequests: 0,
  kycTotalTodayRequests: 0,
  paginationMeta: {
    perPage: 0,
    totalPages: 0,
    totalRows: 0,
  },
  totalRequests: 0,
  totalTodayRequests: 0,
  isLoading: false,
  error: "",
  isChangedStatus: false,
};

export const requestSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestAction.getRequestList.pending, (state) => {
        state.isLoading = true;
        state.isChangedStatus = false;
      })
      .addCase(requestAction.getRequestList.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const { result, paginationMeta, totalRequests, totalTodayRequests } =
            action.payload.data || {};

          if (action.meta.arg.status === KOL_REQUEST_STATUS.INACTIVE) {
            state.requestsProcessing = result;
          }
          if (action.meta.arg.status === KOL_REQUEST_STATUS.REJECTED) {
            state.requestsFailed = result;
          }
          if (action.meta.arg.status === KOL_REQUEST_STATUS.ACTIVE) {
            state.requestsApproved = result;
          }
          state.paginationMeta = paginationMeta;
          state.totalRequests = totalRequests;
          state.totalTodayRequests = totalTodayRequests;
        }
      })
      .addCase(
        requestAction.getRequestList.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      )
      
      .addCase(requestAction.getKycRequestList.pending, (state) => {
        state.isLoading = true;
        state.isChangedStatus = false;
      })
      .addCase(requestAction.getKycRequestList.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const { result, paginationMeta, totalRequests, totalTodayRequests } =
            action.payload.data || {};
          if (action.meta.arg.status === KOL_REQUEST_STATUS.ACTIVE) {
            state.kycRequestsProcessing = result;
          }
          if (action.meta.arg.status === KOL_REQUEST_STATUS.KYC_REJECTED) {
            state.kycRequestsFailed = result;
          }
          if (action.meta.arg.status === KOL_REQUEST_STATUS.KYC_APPROVED) {
            state.kycRequestsApproved = result;
          }
          state.paginationMeta = paginationMeta;
          state.kycTotalRequests = totalRequests;
          state.kycTotalTodayRequests = totalTodayRequests;
        }
      })
      .addCase(
        requestAction.getKycRequestList.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      )
      .addCase(requestAction.approveKycRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        requestAction.approveKycRequest.fulfilled,
        (state, action) => {
          state.isLoading = false;
          if (action.payload) {
            state.isChangedStatus = true;
          }
        }
      )
      .addCase(
        requestAction.approveKycRequest.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      )
      .addCase(requestAction.approveStatusRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        requestAction.approveStatusRequest.fulfilled,
        (state, action) => {
          state.isLoading = false;
          if (action.payload) {
            state.isChangedStatus = true;
          }
        }
      )
      .addCase(
        requestAction.approveStatusRequest.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      );
  },
});

export const requestSliceActions = requestSlice.actions;
export const requestSelector = (state: RootState) => state.requestReducer;
export default requestSlice.reducer;

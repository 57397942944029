import MuiModal from 'src/components/MuiModal';
import { ReactComponent as ModalConfirmSvg } from 'src/assets/icons/modal-confirm.svg';
// import Dropzone from "../Dropzone";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ReactComponent as UploadSvg } from 'src/assets/icons/upload.svg';
import { Controller } from 'react-hook-form';
import './index.scss';
import { TRANSACTION_TYPE } from 'src/store/transaction/transaction.type';
import transactionAction from 'src/store/transaction/transaction.action';
import { Box } from '@mui/system';
import { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ModalConfirm = ({ open, toggleModal, handleApprove }: any) => {
  const [file, setFile] = useState('');
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    evidenceFile: Yup.lazy((value) => {
      return typeof value === 'string'
        ? Yup.string().required(t('REVENUEEXPENDITURE_PAGE.receiptIsRequired')!)
        : Yup.mixed()
            .required(t('REVENUEEXPENDITURE_PAGE.receiptIsRequired')!)
            .test('size', t('REVENUEEXPENDITURE_PAGE.imageSize')!, (value) => {
              return value && value.size <= 10485760;
            });
    }),
  });
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleChange = (e: any) => {
    if (e.target?.files[0]) {
      let url = URL.createObjectURL(e.target?.files[0]);
      if (url) setFile(url);
    }
    e.target.value = null;
  };

  const onSubmit = async (data: any) => {
    if (data.evidenceFile) {
      const { type } = data?.evidenceFile || {};
      const imgExtension = type?.split('/');
      const evidenceImg = `${new Date().getTime()}.${imgExtension[1]}`;
      const payload = {
        fileName: evidenceImg,
        type: TRANSACTION_TYPE.TRANSACTION_EVIDENCE,
        contentType: type,
      };
      const presignedUrl: any = await transactionAction.uploadEvidence(
        payload,
        data.evidenceFile
      );
      handleApprove(presignedUrl);
    }
  };

  return (
    <MuiModal
      open={open}
      titleCancel={t('REVENUEEXPENDITURE_PAGE.back')}
      titleSubmit={t('REVENUEEXPENDITURE_PAGE.confirmPayment')}
      onClose={toggleModal}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={toggleModal}
      maxHeight="510px"
      disabled={!file}
      isHideCloseIcon
    >
      <div className="payment-center">
        <ModalConfirmSvg />
        <div className="payment-modal-title">
          {t('REVENUEEXPENDITURE_PAGE.confirmPaid')}
        </div>
        <div className="payment-modal-sub">
          {t('REVENUEEXPENDITURE_PAGE.attachImage')}
        </div>

        {/* <Dropzone name="evidenceFile" control={control} /> */}
        <Controller
          name="evidenceFile"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => {
            return (
              <Box>
                {file ? (
                  <Box
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 160,
                        width: 350,
                        objectFit: 'cover',
                        maxHeight: { xs: 160, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt=""
                      src={file}
                    />

                    <Box
                      sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                      }}
                    >
                      <CancelIcon color="warning" onClick={() => setFile('')} />
                    </Box>
                  </Box>
                ) : (
                  <Box className="dropzone-container" component="label">
                    <div className="dropzone-wrap">
                      <input
                        name="evidenceFile"
                        hidden
                        accept="image/jpeg,image/png"
                        type="file"
                        onChange={(e: any) => {
                          onChange(e.target?.files[0]);
                          handleChange(e);
                        }}
                      />
                      <UploadSvg />
                      <p>
                        {t('REVENUEEXPENDITURE_PAGE.uploadReceipt')} <br /> (
                        {t('REVENUEEXPENDITURE_PAGE.maxSize10MB')})
                      </p>
                    </div>
                  </Box>
                )}

                {error && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </Box>
            );
          }}
        />
      </div>
    </MuiModal>
  );
};

export default ModalConfirm;

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { separateNumber } from "src/shared/utils/common.util";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import transactionAction from "src/store/transaction/transaction.action";
import { transactionSelector } from "src/store/transaction/transaction.slice";
import Title from "../../components/Title";
import TabsComponent from "./Tabs";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "16px 24px",
  gap: "8px",
  background: "#FFFFFF",
  borderRadius: "12px",
}));

const Transaction = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { total } = useAppSelector(transactionSelector);
  const { totalPaidCashOut, totalPaidPayments } = total || {};

  useEffect(() => {
    dispatch(transactionAction.getTotalTransactions({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
      }}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          columnSpacing={4}
          className="user-statistic"
        >
          <Grid item xs={12} md={6}>
            <Item className="statistic-item" sx={{ height: "100px" }}>
              <Title isSubTitle>
                {t("REVENUEEXPENDITURE_PAGE.totalRevenue")} (VNĐ)
              </Title>
              <Title>{`${separateNumber(totalPaidPayments || 0)}`}</Title>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item className="statistic-item" sx={{ height: "100px" }}>
              <Title isSubTitle type="user-fan">
                {t("REVENUEEXPENDITURE_PAGE.totalExpenditure")} (VNĐ)
              </Title>
              <Title>{`${separateNumber(totalPaidCashOut || 0)}`}</Title>
            </Item>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: "#fff",
          boxShadow:
            "0px 2px 4px rgba(50, 50, 50, 0.04), 0px 6px 12px rgba(50, 50, 50, 0.08)",
          borderRadius: "12px",
        }}
        sx={{ mt: 2 }}
      >
        <Grid item xs={12}>
          <TabsComponent />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(Transaction);

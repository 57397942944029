import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export interface FormInputProps {
  name: string;
  control?: any;
  label?: string;
  setValue?: any;
  placeholder?: string;
  helperText?: string;
  type?: string;
  maxLength: number;
  rows: number;
}

const MuiTextArea = ({
  name,
  control,
  label,
  placeholder = "",
  type = "text",
  maxLength = 500,
  rows = 6,
}: FormInputProps) => {
  return (
    <>
      <div
        style={{
          fontSize: 14,
          fontWeight: 600,
          marginBottom: 2,
          marginTop: 16,
        }}
      >
        {label}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          let color: any = "info";
          if (value && value.trim() && !error) color = "success";
          if (!value || !value.trim()) value = "";
          return (
            <TextField
              type={type}
              color={color}
              helperText={`${value?.length}/${maxLength}`}
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              placeholder={placeholder}
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: maxLength,
              }}
              multiline
              rows={rows}
            />
          );
        }}
      />
    </>
  );
};

export default MuiTextArea;

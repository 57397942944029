import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

import "./index.scss";
import { separateNumber } from "src/shared/utils/common.util";
import MuiPagination from "src/components/MuiPagination";
import { useAppSelector } from "src/store/hooks";
import { format } from "date-fns";
import { transactionSelector } from "src/store/transaction/transaction.slice";
import { useTranslation } from "react-i18next";

const TableComponent = ({
  rows,
  page,
  handleChangePage,
  setOffset,
  tabStatus,
  isLoading
}: {
  rows: Record<string, any>[];
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  setOffset: (newOffset: number) => void;
  disableDetail?: boolean;
  buttonDisabled?: boolean;
  tabStatus?: string;
  isLoading?: boolean;
}) => {
  const { transaction } = useAppSelector(transactionSelector);
  const [totalPage, setTotalPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useTranslation();

  let TABLE_HEAD = [
    "paidPerson",
    "email",
    "amount",
    "paidDate",
    "transactionId",
  ];

  React.useEffect(() => {
    if (Object.keys(transaction).length) {
      const { perPage, totalPages } = transaction;
      setRowsPerPage(perPage);
      setTotalPage(totalPages);
    }
  }, [transaction]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(parseInt(event.target.value, 10));
  };

  return (
    <div className="table-wrap">
      {rows?.length ? (
        <TableContainer className="table-container">
          <Table
            sx={{ minWidth: 650, maxHeight: "460px" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow className="profile-table-head">
                {TABLE_HEAD.map((item: string, index: number) => (
                  <TableCell key={`${item}-${index}`} className="profile-cell">
                    {item ? t(`TRANSACTION_PAGE.${item}`) : item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="profile-table-body">
              {rows.map((row: Record<string, any>, index: number) => {
                const { _id, fanFullName, fanEmail, amount, createdAt } =
                  row || {};
                return (
                  <TableRow
                    key={_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" scope="row">
                      <div className="user-info">
                        <div className="user-name">{fanFullName}</div>
                      </div>
                    </TableCell>
                    <TableCell align="left" scope="row">
                      <div className="user-info">
                        <div className="user-email">{fanEmail}</div>
                      </div>
                    </TableCell>
                    <TableCell align="left" scope="row">
                      <div className="user-type" style={{ color: "#000" }}>
                        {`${separateNumber(amount)} đ`}
                      </div>
                    </TableCell>
                    <TableCell align="left" scope="row">
                      <div>{format(new Date(createdAt), "dd/MM/yyyy")}</div>
                    </TableCell>
                    <TableCell align="left" scope="row">
                      <div className="user-info">
                        <div className="transaction-id">{_id}</div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          className="midol-subtitle"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "70vw",
            padding: "15px 0",
          }}
        >
          {t("noData")}
        </div>
      )}

      {rows?.length ? (
        <MuiPagination
          count={totalPage}
          page={page}
          handleChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
};

export default React.memo(TableComponent);

import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const CSkeleton = () => (
  <Box
    sx={{
      height: "max-content"
    }}
  >
    <Skeleton variant="rectangular" animation="wave" sx={{ my: 1, mx: 1 }} />
  </Box>
);

const LoadingSkeleton = ({ itemRow = 1, itemColumn = 1 }: { itemRow?: number; itemColumn?: number  }) => {
  return (
    <TableBody>
      {
        [...Array(itemRow)]?.map((_, index) => {
          return (
            <TableRow 
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className="user-row"
              key={index}
            >
              {
                [...Array(itemColumn)]?.map((i) => {
                  return (
                    <TableCell align="left" scope="row" className="user-cell">
                      <CSkeleton />
                    </TableCell>
                  )
                })
              }
            </TableRow>
          )
        })
      }
    </TableBody>
  )
}

export default LoadingSkeleton;

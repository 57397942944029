import { createSlice, current } from "@reduxjs/toolkit";
import usersAction from "./users.action";
import { RootState } from "../index";

export interface UsersState {
  rawUsers: Record<string, any>[];
  users: Record<string, any>[];
  fans: Record<string, any>[];
  idols: Record<string, any>[];
  totalUsers: number;
  totalFan: number;
  totalIdol: number;
  isLoading: boolean;
  error: string;
  isUpdated: boolean;
  paginationMeta: Record<string, any>;
}

const initialState: UsersState = {
  rawUsers: [],
  users: [],
  fans: [],
  idols: [],
  totalUsers: 0,
  totalFan: 0,
  totalIdol: 0,
  isLoading: false,
  error: "",
  isUpdated: false,
  paginationMeta: {},
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    searchUsers: (state, action) => {
      const prevState = current(state);
      const { searchString, isSortByFavorite } = action.payload;
      let filterUsers = prevState.rawUsers.filter((user) => {
        const { _id, fullName, email } = user || {};
        return (
          _id.includes(searchString) ||
          (fullName && fullName.toLowerCase().includes(searchString)) ||
          (email && email.toLowerCase().includes(searchString))
        );
      });
      if (isSortByFavorite) {
        filterUsers = filterUsers.filter(c => c.isFavorite === isSortByFavorite);
      }
      state.users = filterUsers;
      state.fans = filterUsers.filter((item) => item.userType === "fan");
      state.idols = filterUsers.filter((item) => item.userType === "idol");
    },
    sortByFavorite: (state, action) => {
      const prevState = current(state);
      const { isSortByFavorite, searchString } = action.payload;
      let filterUsers = prevState.rawUsers;
      if (isSortByFavorite) {
        filterUsers = filterUsers.filter(c => c.isFavorite === true)
      }
      if (searchString) {
        filterUsers = filterUsers.filter((user) => {
          const { _id, fullName, email } = user || {};
          return (
            _id.includes(searchString) ||
            (fullName && fullName.toLowerCase().includes(searchString)) ||
            (email && email.toLowerCase().includes(searchString))
          );
        });
      }
      state.users = filterUsers;
      state.idols = filterUsers.filter((item) => item.userType === "idol");
    },
    resetListUser: (state) => {
      const prevState = current(state);
      state.users = prevState.rawUsers;
      state.fans = prevState.rawUsers.filter((item) => item.userType === "fan");
      state.idols = prevState.rawUsers.filter(
        (item) => item.userType === "idol"
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usersAction.getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(usersAction.getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const { result, totalUsers, totalFan, totalIdol, paginationMeta } =
            action.payload.data || {};
          state.users = result;
          state.paginationMeta = paginationMeta;
          state.totalUsers = totalUsers;
          state.totalFan = totalFan;
          state.totalIdol = totalIdol;
        }
      })
      .addCase(
        usersAction.getUsers.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      )
      .addCase(usersAction.updateUser.pending, (state) => {
        state.isLoading = true;
        state.isUpdated = false;
      })
      .addCase(usersAction.updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const { _id, userStatus } = action.payload.data || {};
          state.users = state.users.map((item) => {
            if (item._id === _id) {
              return { ...item, userStatus };
            }
            return item;
          });
        }
      })
      .addCase(
        usersAction.updateUser.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.isUpdated = false;
          state.error = action.error;
        }
      )
      .addCase(usersAction.setFavoriteKol.pending, (state) => {
        state.isLoading = true;
        state.isUpdated = false;
      })
      .addCase(usersAction.setFavoriteKol.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const { _id, isFavorite } = action.payload.data || {};
          state.users = state.users.map((item) => {
            if (item._id === _id) {
              return { ...item, isFavorite };
            }
            return item;
          });
        }
      })
      .addCase(
        usersAction.setFavoriteKol.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.isUpdated = false;
          state.error = action.error;
        }
      );
  },
});

export const usersSliceActions = usersSlice.actions;
export const usersSelector = (state: RootState) => state.usersReducer;
export default usersSlice.reducer;

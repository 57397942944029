import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactElement } from 'react';
import { RootState } from '../../store';
import { getToken } from 'src/shared/services/storage.service';
import { resetAuthState } from 'src/store/Auth/auth.slice';

function PrivateRoute({ children }: { children: ReactElement }) {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const token = getToken();
  const { isLoggined = false } = auth;
  if (!isLoggined || !token) {
    dispatch(resetAuthState());
    return <Navigate to="/dang-nhap" />;
  }
  return children;
}

export { PrivateRoute };

const TOKEN = 'TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const DEVICE_ID = 'DEVICE_ID';

export function setToken(value: any) {
  if (typeof window === 'undefined') return '';
  localStorage.setItem(TOKEN, JSON.stringify(value));
}

export function getToken() {
  if (typeof window === 'undefined') return '';
  const token: string | null = localStorage.getItem(TOKEN);

  if (!token || token === 'undefined') return '';
  return JSON.parse(token);
}

export function removeToken() {
  if (typeof window === 'undefined') return;
  localStorage.removeItem(TOKEN);
}

export function setRefreshToken(value: any) {
  if (typeof window === 'undefined') return '';
  localStorage.setItem(REFRESH_TOKEN, JSON.stringify(value));
}

export function getRefreshToken() {
  if (typeof window === 'undefined') return '';
  const token: string | null = localStorage.getItem(REFRESH_TOKEN);
  if (!token || token === 'undefined') return '';
  return JSON.parse(token);
}

export function removeRefreshToken() {
  if (typeof window === 'undefined') return;
  localStorage.removeItem(REFRESH_TOKEN);
}

export function removeAllStorage() {
  if (typeof window === 'undefined') return;
  localStorage.clear();
  sessionStorage.clear();
}

export function setDeviceId(value: string) {
  if (typeof window === 'undefined') return '';
  localStorage.setItem(DEVICE_ID, JSON.stringify(value));
}

export function getDeviceId() {
  if (typeof window === 'undefined') return '';
  return JSON.parse(String(localStorage.getItem(DEVICE_ID)));
}
